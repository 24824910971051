import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores";
import { Roles } from "@/UserRoles";
import { authenticationService } from "@/services";
import { trace } from "@/utils";

// Public views
import AboutView from "@/views/About.vue";
import HomeView from "@/views/Home.vue";
import InitiateSignInView from "@/views/InitiateSignIn.vue";
import SignInView from "@/views/SignIn.vue";
import ContactView from "@/views/Contact.vue";
import ScheduleView from "@/views/Schedule.vue";
import SpecialsView from "@/views/Specials.vue";
import ProgramsView from "@/views/Programs.vue";
import FaqView from "@/views/FAQ.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicy.vue";
const signInUrl = "/initiatesignin";
const publicRoutes = [
	{ path: "/", name: "home", component: HomeView },
	{ path: "/about", name: "about", component: AboutView },
	{ path: signInUrl, name: "initiatesignin", component: InitiateSignInView },
	{ path: "/signin", name: "signin", component: SignInView },
	{ path: "/contact", name: "contact", component: ContactView },
	{ path: "/schedule", name: "schedule", component: ScheduleView },
	{ path: "/specials", name: "specials", component: SpecialsView },
	{ path: "/programs", name: "programs", component: ProgramsView },
	{ path: "/faq", name: "faq", component: FaqView },
	{ path: "/privacy-policy", name: "privacy-policy", component: PrivacyPolicyView }
];

// Self-Service views
import DashboardView from "@/modules/selfService/views/Dashboard.vue";
import PayStubsView from "@/modules/selfService/views/PayStubs.vue";
import SelfServiceLayout from "@/modules/selfService/views/SelfServiceLayout.vue";
const selfServiceTemplateRoute = {
	path: "/my", name: "my", component: SelfServiceLayout, children: [
		{ path: "home", name: "myHome", component: DashboardView },
		{ path: "pay-stubs", name: "myPayStubs", component: PayStubsView, meta: { authorize: [Roles.Representative] } },
	]
};

// Academic views
import AcademicTemplateView from "@/modules/academic/views/AcademicTemplate.vue";
import AcademicHomeView from "@/modules/academic/views/AcademicHome.vue";
import ClassManagementView from "@/modules/academic/views/ClassManagement.vue";
import CourseManagementView from "@/modules/academic/views/CourseManagement.vue";
import StudentManagementView from "@/modules/academic/views/StudentManagement.vue";
import ProspectManagementView from "@/modules/academic/views/ProspectManagement.vue";
import AcademicSettingsView from "@/modules/academic/views/Settings.vue";
const academicTemplateRoute = {
	path: "/academic", name: "academic", component: AcademicTemplateView, children: [
		{ path: "home", name: "academicHome", component: AcademicHomeView, meta: { authorize: [Roles.Instructor, Roles.Director] } },
		{ path: "courses", name: "academicCourses", component: CourseManagementView, meta: { authorize: [Roles.Director] } },
		{ path: "classes", name: "academicClasses", component: ClassManagementView, meta: { authorize: [Roles.Instructor] } },
		{ path: "students", name: "academicStudents", component: StudentManagementView, meta: { authorize: [Roles.Instructor, Roles.Director] } },
		{ path: "prospects", name: "academicProspects", component: ProspectManagementView, meta: { authorize: [Roles.Representative] } },
		{ path: "settings", name: "academicSettings", component: AcademicSettingsView, meta: { authorize: [Roles.Director] } },
	]
};

// Accounting views
import AccountingLayout from "@/modules/accounting/views/AccountingLayout.vue";
import AccountingHomeView from "@/modules/accounting/views/AccountingHome.vue";
import AccountingSettingsView from "@/modules/accounting/views/Settings.vue";
import AccountManagementView from "@/modules/accounting/views/AccountManagement.vue";
import AccountLedgerView from "@/modules/accounting/views/AccountLedger.vue";
import EmployeeManagement from "@/modules/accounting/views/EmployeeManagement.vue";
import InvoiceManagementView from "@/modules/accounting/views/InvoiceManagement.vue";
import InvoicePrintView from "@/modules/accounting/views/InvoicePrint.vue";
import PartyManagementView from "@/modules/accounting/views/PartyManagement.vue";
import PayrollRunManagementView from "@/modules/accounting/views/PayrollRunManagement.vue";
import PayrollScheduleManagement from "@/modules/accounting/views/PayrollScheduleManagement.vue";
import ProductManagementView from "@/modules/accounting/views/ProductManagement.vue";
const accountingTemplateRoute = {
	path: "/accounting", name: "accounting", component: AccountingLayout, children: [
		{ path: "home", name: "accountingHome", component: AccountingHomeView, meta: { authorize: [Roles.Representative, Roles.Accountant] } },
		{ path: "accounts", name: "accountingAccounts", component: AccountManagementView, meta: { authorize: [Roles.Accountant] } },
		{ path: "ledger/:accountId", name: "accountingLedger", component: AccountLedgerView, meta: { authorize: [Roles.Accountant] } },
		{ path: "invoices", name: "accountingInvoices", component: InvoiceManagementView, meta: { authorize: [Roles.Representative, Roles.Accountant] } },
		{ path: "invoice/print/:id", name: "accountingInvoicePrint", component: InvoicePrintView, meta: { authorize: [Roles.Representative, Roles.Accountant] } },
		{ path: "parties", name: "accountingParties", component: PartyManagementView, meta: { authorize: [Roles.Representative, Roles.Accountant] } },
		{ path: "products", name: "accountingProducts", component: ProductManagementView, meta: { authorize: [Roles.Representative, Roles.Accountant] } },
		{ path: "payroll-schedules", name: "accountingPayrollSchedules", component: PayrollScheduleManagement, meta: { authorize: [Roles.Director] } },
		{ path: "employees", name: "accountingEmployees", component: EmployeeManagement, meta: { authorize: [Roles.Director] } },
		{ path: "settings", name: "accountingSettings", component: AccountingSettingsView, meta: { authorize: [Roles.Accountant] } },
		{ path: "payroll-runs", name: "accountingPayrollRuns", component: PayrollRunManagementView, meta: { authorize: [Roles.Accountant] } },
	]
};

// Development views
import DevelopmentTemplateView from "@/modules/development/views/DevelopmentTemplate.vue";
import DevelopmentHomeView from "@/modules/development/views/DevelopmentHome.vue";
import OperationDetailsView from "@/modules/development/views/OperationDetails.vue";
import RecentErrorsView from "@/modules/development/views/RecentErrors.vue";
import RecentOperationsView from "@/modules/development/views/RecentOperations.vue";
const developmentTemplateRoute = {
	path: "/development", name: "development", component: DevelopmentTemplateView, children: [
		{ path: "home", name: "developmentHome", component: DevelopmentHomeView, meta: { authorize: [Roles.Developer] } },
		{ path: "recent-operations", name: "developmentRecentOperations", component: RecentOperationsView, meta: { authorize: [Roles.Developer] } },
		{ path: "recent-errors", name: "developmentRecentErrors", component: RecentErrorsView, meta: { authorize: [Roles.Developer] } },
		{ path: "operation/:operationId", name: "developmentOperationDetails", component: OperationDetailsView, meta: { authorize: [Roles.Developer] } },
	]
};

// Set up all routes.
const routes = publicRoutes
	.concat([selfServiceTemplateRoute])
	.concat([academicTemplateRoute])
	.concat([accountingTemplateRoute])
	.concat([developmentTemplateRoute]);

// Configure router.
var _router = null;
const getRouter = function() {
	return _router || initializeRouter();
};
const initializeRouter = function() {
	_router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes: routes,
		scrollBehavior(to, from, savedPosition) {
			return { top: 0 }
		}
	});

	_router.beforeEach(async (to, from, next) => {
		const publicPaths = publicRoutes.map(element => element.path);
		const authenticationRequired = !publicPaths.includes(to.path);

		if (authenticationRequired) {
			const userStore = useUserStore();
			const userAccount = userStore.getAccount();

			const isAuthenticated = !!userAccount && userAccount.expiration > new Date(Date.now());
			if (!isAuthenticated) {
				userStore.setRedirectUrl(to.fullPath);
				return next({ path: signInUrl });
			}

			const { authorize } = to.meta;
			if (authorize && authorize.length > 0) {
				var isUserAuthorized = false;
				for (var i = 0; i < authorize.length; i++) {
					if (userAccount.roles.includes(authorize[i])) {
						isUserAuthorized = true;
						break;
					}
				}
				if (!isUserAuthorized) {
					return next({ path: "/my/home" });
				}
			}
		}

		next();
	});

	return _router;
};

function getTopLevelSecuredRoutes() {
	return [selfServiceTemplateRoute, academicTemplateRoute, accountingTemplateRoute, developmentTemplateRoute];
}

function performPostSignInRedirect() {
	var redirectUrl = useUserStore().popRedirectUrl();
	trace("Redirecting to post-login page: ", redirectUrl);
	getRouter().push({ path: redirectUrl });
}

export { getRouter, getTopLevelSecuredRoutes, initializeRouter, performPostSignInRedirect, publicRoutes, selfServiceTemplateRoute, academicTemplateRoute, accountingTemplateRoute, developmentTemplateRoute };
