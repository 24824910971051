<script setup>
	import { onMounted } from "vue";
	import { authenticationService } from "@/services/authenticationService";
	import { performPostSignInRedirect } from "@/router";
import { useUserStore } from "@/stores";

	async function signInUser() {
		await authenticationService.signIn(
			account => {
				if (account) {
					useUserStore().setAccount(account);
					performPostSignInRedirect(); 
				}
			},
			error => console.error(error)
		);
	}

	onMounted(async () => {
		await signInUser();
	});
</script>

<template>
	<div class="container text-center">
		<h3>Attempting to sign you in...</h3>
	</div>
</template>
