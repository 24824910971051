import { defineStore, storeToRefs } from "pinia";
import { trace } from "@/utils";

export const useUserStore = defineStore({
	id: "UserStore",
	state: () => ({
		account: null, // { username: String, name: String, roles: [String], token }
		originalaccount: null,
		returnUrl: null
	}),
	actions: {
		clearAccount() {
			this.account = null;
			localStorage.removeItem("UserAccessToken");
		},
		endImpersonation() {
			const originalaccount = this.getOriginalAccount();
			if (!originalaccount)
				return ; //guard clause
			this.setAccount(originalaccount);
			this.originalaccount = null;
		},
		getAccessToken() {
			if (!this.account)
				return null;

			return this.account.token;
		},
		getAccount() {
			if (!this.account) {
				const storedAccount = localStorage.getItem("UserAccessToken");
				if (!!storedAccount) {
					try {
						this.account = JSON.parse(storedAccount);
						if (!!this.account?.expiration) {
							this.account.expiration = new Date(this.account.expiration);
							if (this.account.expiration < new Date(Date.now())) {
								trace("Account expired; requiring sign-in.");
								this.clearAccount();
							}
						}
					}
					catch (error) {
						console.error("Stored account is invalid.", error);
						this.clearAccount();
					}
				}
			}
			return this.account;
		},
		getOriginalAccount() {
			if (!this.originalaccount) {
				const originalaccountJson = localStorage.getItem("OriginalAccount");
				if (originalaccountJson) {
					const originalaccount = JSON.parse(originalaccountJson);
					this.originalaccount = originalaccount;
				}
			}
			return this.originalaccount;
		},
		impersonateRole(role) {
			const currentAccount = this.getAccount();
			const impersonate = structuredClone(currentAccount);
			impersonate.roles.push (role);
			this.setOriginalAccount(currentAccount);
			this.setAccount(impersonate);
		},
		isImpersonating() {
			return !!this.originalaccount;
		},
		isSignedIn() {
			return !!this.getAccount();
		},

		popRedirectUrl() {
			const returnUrl = this.returnUrl;
			return returnUrl ?? "/my";
		},
		setAccount(token) {
			this.account = token;
			localStorage.setItem("UserAccessToken", JSON.stringify(token));
		},
		setOriginalAccount(account) {
			this.originalaccount = account;
			localStorage.setItem("OriginalAccount", JSON.stringify(account));
		},
		setRedirectUrl(returnUrl) {
			this.returnUrl = returnUrl;
		}
	}
});
