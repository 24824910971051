<script>
import { VBtn, VCard, VCardText } from "vuetify/components";
import { useUserStore } from "@/stores";
import { Roles } from "@/UserRoles";

export default {
	name: "DevelopmentHomeView",

	components: {
		VCard, VCardText,
	},

	created() {
		const userStore = useUserStore();
		this.account = userStore.getAccount();
	},

	data: () => ({
		account: null,
		Roles: Roles,
	}),

	methods: {
		endImpersonation () {
			const userStore = useUserStore();
			userStore.endImpersonation();
			console.log ("The current roles are ", userStore.getAccount().roles);
		},

		isImpersonating () {
			const userStore = useUserStore();
			return userStore.isImpersonating();
		},

		runAs (role) {
			console.log ("Running as ", role);
			const userStore = useUserStore();
			userStore.impersonateRole(role); 
		}
	}
}

</script>

<template>
	<div class="container">
		<h1>Development Home</h1>

		<div v-if="this.account.roles.includes(Roles.Developer)" class="my-4">
			<h3>Developer Tools</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'developmentRecentOperations' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Recent Operations</template>
							<v-card-text>View recent operations in the trace log.</v-card-text>
						</v-card>
					</router-link>
				</div>
				<div class="col-auto my-1">
					<router-link :to="{ name: 'developmentRecentErrors' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Recent Errors</template>
							<v-card-text>View recent errors in the trace log.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
			<VBtn 
			v-if="!isImpersonating()"
			outlined color="primary" title="Run As Director" class="mr-4 run-as-director-action" @click="runAs(Roles.Director)"
				>Run As Director</VBtn>
			<VBtn 
			v-if="!isImpersonating()"
			outlined color="primary" title="Run As Instructor" class="mr-4 run-as-instructor-action" @click="runAs(Roles.Instructor)"
				>Run As Instructor</VBtn>
			<VBtn 
			v-if="isImpersonating()"
			outlined color="primary" title="End Impersonation"  class='end-impersonation-action' @click="endImpersonation()"
			>End Impersonation</VBtn>
		</div>
	</div>
</template>
