<script>
import { VApp, VAppBar, VAppBarTitle, VBadge, VBtn, VCol, VFadeTransition, VFooter, VMain, VRow, VSpacer } from "vuetify/components";
import largeLucentaryLogoUrl from "@/assets/img/lucentary-color-nobg.svg";
import smallLucentaryLogoUrl from "@/assets/img/lucentary-color-nobg-sm.svg";
import { publicRoutes, selfServiceTemplateRoute, academicTemplateRoute, accountingTemplateRoute, developmentTemplateRoute, getRouter } from "@/router";
import { authenticationService, executeServiceCall, selfServiceService } from "./services";
import { useUserStore } from "./stores";

const getAllowedRoutes = function(routes, account) {
	if (!routes || routes.length === 0 || !account)
		return 0;

	const allowedRoutes = routes.filter(route => {
		if (!route.meta || !route.meta.authorize?.length)
			return true;
		if (!account?.roles)
			return false;
		const allowedRoles = account.roles.filter(role => route.meta.authorize.includes(role));
		return allowedRoles.length > 0;
	});
	return allowedRoutes;
};
const getAllowedLinkCount = function (routes, account) {
	const allowedRoutes = getAllowedRoutes(routes, account);
	return allowedRoutes.length;
};

export default {
	name: "App",

	components: {
		VApp, VAppBar, VAppBarTitle, VBadge, VBtn, VCol, VFadeTransition, VFooter, VMain, VRow, VSpacer,
	},

	async created() {
		this.userStore = useUserStore();
		this.checkActiveAccount();
	},

	data: () => ({
		largeLucentaryLogoUrl: largeLucentaryLogoUrl,
		showNavigationDrawer: false,
		smallLucentaryLogoUrl: smallLucentaryLogoUrl,
		topNavigationLinks: [],
		userStore: null
	}),

	computed: {
		isImpersonating() {
			return this.userStore?.isImpersonating(); 
		}
	},	

	watch: {
		$route(to, from) {
			this.checkActiveAccount();
		},
		isImpersonating(to, from) {
			this.checkActiveAccount();
		}
	},

	methods: {
		checkActiveAccount() {
			const account = this.userStore.getAccount();
			if (!account) {
				this.topNavigationLinks = [];
				return;
			}

			const existingTopNavigationLinks = this.topNavigationLinks;
			const topNavigationLinks = [
				{ icon: "mdi-view-dashboard", title: "My Dashboard", routeName: "my", allowedLinkCount: getAllowedLinkCount(selfServiceTemplateRoute.children, account), hasNotifications: false },
				{ icon: "mdi-school", title: "Academics", routeName: "academicHome", allowedLinkCount: getAllowedLinkCount(academicTemplateRoute.children, account), hasNotifications: false },
				{ icon: "mdi-currency-usd", title: "Accounting", routeName: "accountingHome", allowedLinkCount: getAllowedLinkCount(accountingTemplateRoute.children, account), hasNotifications: false },
				{ icon: "mdi-code-braces", title: "Development", routeName: "developmentHome", allowedLinkCount: getAllowedLinkCount(developmentTemplateRoute.children, account), hasNotifications: false },
			];
			topNavigationLinks.forEach(topNavigationLink => {
				const existingLink = existingTopNavigationLinks.find(link => link.title === topNavigationLink.title);
				if (!!existingLink)
					topNavigationLink.hasNotifications = existingLink.hasNotifications;
			});
			this.topNavigationLinks = topNavigationLinks.filter(link => link.allowedLinkCount > 0);

			const accountingLink = this.topNavigationLinks.find(link => link.title === "Accounting");
			if (!!accountingLink) {
				executeServiceCall(selfServiceService.accounting.notifications)
					.then(({ data }) => accountingLink.hasNotifications = !!data?.length)
					.catch(error => console.error(error));
			}

		},
		async getAccountingHasNotifications() {
			return true;
		},
		async getNoNotifications() {
			return false;
		},
		signIn() {
			this.userStore.clearAccount();
			getRouter().push({ name: "initiatesignin" });
		},
		signOut() {
			authenticationService.signOut(
				 () => {
					this.userStore.clearAccount();
					this.checkActiveAccount();
				},
				error => console.error(error));
		},
	}
}
</script>

<template>
	<VApp>
		<VAppBar app>
			<template v-slot:append>
				<VAppBarNavIcon color="primary" class="d-lg-none" @click="showNavigationDrawer = !showNavigationDrawer"/>
				<div class="d-none d-lg-flex top-navigation-links">
					<VBtn v-for="link in this.topNavigationLinks" color="primary" :to="{ name: link.routeName }" :title="link.title" class="text-none" :class="`${link.routeName}-action`">
						<VBadge v-if="link.hasNotifications" color="error" class="notification-badge" dot>
							<i :class="`mdi ${link.icon} mdi-24px`"></i>
						</VBadge>
						<i v-else :class="`mdi ${link.icon} mdi-24px`"></i>
					</VBtn>
					<VBtn :to="{ name: 'contact' }" title="Contact Us" class="mx-1 view-contact-action btn btn-sm btn-primary">Request Info</VBtn>
					<!-- <VBtn :to="{ name: 'programs' }" title="View Courses" class="mx-1 view-courses-action btn btn-sm btn-primary">View Courses</VBtn> -->
					<VBtn v-if="!this.userStore.isSignedIn()" color="primary" class="signin mx-1 btn btn-sm btn-light" v-on:click="signIn">Sign In</VBtn>
					<VBtn v-if="this.userStore.isSignedIn()" color="primary" class="signout mx-1 btn btn-sm btn-light" v-on:click="signOut">Sign Out</VBtn>
				</div>
			</template>
			<VAppBarTitle>
				<VRow>
					<VCol class="v-col-auto">
						<RouterLink :to="{ name: 'home' }" class="navbar-brand">
							<img :src="largeLucentaryLogoUrl" height="50" class="d-none d-sm-inline" />
							<img :src="smallLucentaryLogoUrl" height="50" class="d-sm-none" />
						</RouterLink>
					</VCol>
				</VRow>
			</VAppBarTitle>
		</VAppBar>
		<VNavigationDrawer v-model="showNavigationDrawer" absolute temporary location="top" color="primary">
			<VList nav density="compact">
				<VListItem v-for="(link, i) in this.topNavigationLinks" :key="i" :title="link.title" :to="{ name: link.routeName }">
					<template v-slot:prepend>
						<VBadge v-if="link.hasNotifications" color="error" class="notification-badge mr-2" dot>
							<i :class="`mdi ${link.icon} mdi-24px`"></i>
						</VBadge>
						<i v-else :class="`mdi ${link.icon} mdi-24px mr-2`"></i>
					</template>
				</VListItem>
				<VListItem title="About" :to="{ name: 'about' }"/>
				<VListItem title="Contact" :to="{ name: 'contact' }"/>
				<VListItem v-if="!this.userStore.isSignedIn()" title="Sign In" @click="signIn"/>
				<VListItem v-if="this.userStore.isSignedIn()" title="Sign Out" @click="signOut"/>
			</VList>
		</VNavigationDrawer>
		<VMain>
			<div class="main-content">
				<main role="main" class="pb-3">
					<RouterView v-slot="{ Component, route }">
						<VFadeTransition mode="out-in" :hide-on-leave="true">
							<div :key="route.name">
								<component :is="Component"/>
							</div>
						</VFadeTransition>
					</RouterView>
				</main>
			</div>
		</VMain>

		<VFooter class="footer bg-light">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 h-100 text-center my-auto">
						<ul class="list-inline mb-1">
							<li class="list-inline-item"><a target="_blank" href="https://m.facebook.com/61554542814302/"><i class="mdi mdi-24px mdi-facebook"></i></a></li>
							<li class="list-inline-item">&nbsp;</li>
							<li class="list-inline-item"><a target="_blank" href="https://www.linkedin.com/company/lucentary-academy"><i class="mdi mdi-24px mdi-linkedin"></i></a></li>
							<li class="list-inline-item">&nbsp;</li>
							<li class="list-inline-item"><a target="_blank" href="https://maps.app.goo.gl/MbnqXNZ98wS4Fz1J6"><i class="mdi mdi-24px mdi-google"></i></a></li>
							<li class="list-inline-item">&nbsp;</li>
							<li class="list-inline-item"><a target="_blank" href="https://www.yelp.com/biz/lucentary-academy-fort-worth-2"><img height="24" src="https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_design_cdn/cabcef1daea2/assets/img/brand/burst_red.svg" /></a></li>
						</ul>
						<ul class="list-inline mb-0">
							<li class="list-inline-item"><a href="https://free-4841968.webadorsite.com/" target="_blank">Our Blog</a></li>
							<li class="list-inline-item">⋅</li>
							<li class="list-inline-item"><RouterLink :to="{ name: 'about' }">About Us</RouterLink></li>
							<li class="list-inline-item">⋅</li>
							<li class="list-inline-item"><RouterLink :to="{ name: 'specials' }">Our Specials</RouterLink></li>
							<li class="list-inline-item">⋅</li>
							<li class="list-inline-item"><RouterLink :to="{ name: 'programs' }">Our Programs</RouterLink></li>
							<!-- <li class="list-inline-item">⋅</li> -->
						</ul>
						<ul class="list-inline mb-2">
							<li class="list-inline-item"><a href="#!">Terms of Use</a></li>
							<li class="list-inline-item">⋅</li>
							<li class="list-inline-item"><RouterLink :to="{ name: 'privacy-policy' }">Privacy Policy</RouterLink></li>
							<li class="list-inline-item">⋅</li>
							<li class="list-inline-item"><RouterLink :to="{ name: 'faq' }">FAQ</RouterLink></li>
						</ul>
						<p><a href="https://www.bbb.org/us/tx/fort-worth/profile/computer-software-developers/lucentary-academy-inc-0825-1000230038/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-austin.bbb.org/seals/blue-seal-200-42-bbb-1000230038.png" style="border: 0;" alt="Lucentary Academy Inc BBB Business Review" /></a></p>
						<p class="text-muted small mb-4 mb-lg-0">&copy; Lucentary Academy Inc 2024. All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</VFooter>
	</VApp>
</template>
