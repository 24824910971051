<script>
import { VCard, VCardText } from "vuetify/components";
import { useUserStore } from "@/stores";
import { Roles } from "@/UserRoles";

export default {
	name: "AcademicHomeView",

	components: {
		VCard, VCardText,
	},

	created() {
		const userStore = useUserStore();
		this.account = userStore.getAccount();
	},

	data: () => ({
		account: null,
		Roles: Roles,
	}),
}
</script>

<template>
	<div class="container">
		<h1>Academic Management Home</h1>

		<div v-if="this.account.roles.includes(Roles.Director)" class="my-4">
			<h3>Director Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'academicCourses' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Course Management</template>
							<v-card-text>Allows you to create, edit, and delete courses for use by Instructors and Students.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>

		<div v-if="this.account.roles.includes(Roles.Instructor)" class="my-4">
			<h3>Instructor Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'academicClasses' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Class Management</template>
							<v-card-text>Allows you to create, edit, and delete classes associated with Courses for use by Students.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>
		
	</div>
</template>
