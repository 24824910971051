import { parseNumber } from "@/utils";
import { webApiService } from "./webApiService";

const academicService = {
	class: {
		// Methods
		byId: async function(id) {
			// 200, <LucentaryClass>
			return await webApiService.executeGet(`Academic/Class/ById?Id=${id}`);
		},
		delete: async function (id) {
			// 200, empty
			return await webApiService.executePost(`Academic/Class/Delete`, { id });
		},
		coursesAndClasses: async function(includeDeletedClasses) {
			// 200, [<CourseWithClasses>]
			return await webApiService.executeGet(`Academic/Class/CoursesAndClasses?IncludeDeleted=${includeDeletedClasses}`);
		},
		reactivate: async function (id) {
			// 200, empty
			return await webApiService.executePost(`Academic/Class/Reactivate`, { id });
		},
		save: async function(lucentaryClass) {
			// 200, { id: <String> }
			// 400, { name: [<String>], courseName: [<String>] }
			var formData = new FormData();
			if (lucentaryClass.id)
				formData.append("id", lucentaryClass.id);
			formData.append("name", lucentaryClass.name);
			formData.append("courseName", lucentaryClass.courseName);
			formData.append("description", lucentaryClass.description);
			if (lucentaryClass.objectives)
				lucentaryClass.objectives.forEach(objective => formData.append("objectives", objective));
			formData.append("isDeleted", lucentaryClass.isDeleted);
			if (lucentaryClass.materialFiles)
				lucentaryClass.materialFiles.forEach(file => formData.append("materialFiles", file));
			return await webApiService.executePost("Academic/Class/Save", formData);
		},
	},
	classAttendance: {
		referenceData: async function() {
			return await webApiService.executeGet("Academic/ClassAttendance/ReferenceData");
		},
		save: async function(classRoster) {
			return await webApiService.executePost("Academic/ClassAttendance/Save", classRoster);
		},
	},
	course: {
		// Methods
		byId: async function(id) {
			// 200, <Course>
			return await webApiService.executeGet(`Academic/Course/ById?Id=${id}`);
		},
		delete: async function (id) {
			// 200, empty
			return await webApiService.executePost(`Academic/Course/Delete`, { id });
		},
		list: async function(includeActive, includeDeleted) {
			// 200, [<CourseHeader>]
			return await webApiService.executeGet(`Academic/Course/List?IncludeActive=${includeActive}&IncludeDeleted=${includeDeleted}`);
		},
		reactivate: async function (id) {
			// 200, empty
			return await webApiService.executePost(`Academic/Course/Reactivate`, { id });
		},
		save: async function(course) {
			// 200, { id: <String> }
			// 400, { name: [<String>] }
			var courseToPost = {
				id: course.id,
				cost: parseNumber(course.cost),
				exemptionApprovalDate: course.exemptionApprovalDate,
				exemptionSubmissionDate: course.exemptionSubmissionDate,
				explanation: course.explanation,
				externshipHours: parseNumber(course.externshipHours),
				isCertifiedToMeetTwcExemptionCriteria: !!course.isCertifiedToMeetTwcExemptionCriteria,
				labHours: parseNumber(course.labHours),
				lectureHours: parseNumber(course.lectureHours),
				name: course.name,
				objectives: course.objectives,
			}
			return await webApiService.executePost("Academic/Course/Save", courseToPost);
		},
	},
	prospect: {
		byId: async function(id) {
			return await webApiService.executeGet(`Academic/Prospect/ById?Id=${id}`);
		},
		list: async function() {
			return await webApiService.executeGet("Academic/Prospect/List");
		},
		listConvertedToStudent: async function () {
			return await webApiService.executeGet("Academic/Prospect/ListConvertedToStudent");
		},
		listDoNotContact: async function () {
			return await webApiService.executeGet("Academic/Prospect/ListDoNotContact");
		},
		listFollowUpToday: async function () {
			return await webApiService.executeGet("Academic/Prospect/ListFollowUpToday");
		},
		listStale: async function () {
			return await webApiService.executeGet("Academic/Prospect/ListStale");
		},
		save: async function(prospect) {
			return await webApiService.executePost("Academic/Prospect/Save", prospect);
		},
		setAsActive: async function(request) {
			return await webApiService.executePost("Academic/Prospect/SetAsActive", request);
		},
		setAsConvertedToStudent: async function(request) {
			return await webApiService.executePost("Academic/Prospect/SetAsConvertedToStudent", request);
		},
		setAsDoNotContact: async function(request) {
			return await webApiService.executePost("Academic/Prospect/SetAsDoNotContact", request);
		},
		setFollowUpDate: async function(request) {
			return await webApiService.executePost("Academic/Prospect/SetFollowUpDate", request);
		},
	},
	setting: {
		addClassTime: async function(newClassTime) {
			return await webApiService.executePost("Academic/Setting/AddClassTime", { classTime: newClassTime });
		},
		availableClassTimes: async function() {
			return await webApiService.executeGet("Academic/Setting/AvailableClassTimes");
		},
		removeClassTime: async function(classTime) {
			return await webApiService.executePost("Academic/Setting/RemoveClassTime", { classTime: classTime });
		},
	},
	student: {
		byId: async function(id) {
			return await webApiService.executeGet(`Academic/Student/ById?Id=${id}`);
		},
		list: async function() {
			return Promise.resolve( { data: [
				{
					name: "Misa Test",
					address: "123 Main Denver, CO 80120",
					personalEmail: "misa@gmail.com",
					lucentaryEmail: "misa@lucentary.com",
				},
			]}); 
		},
		save: async function(student) {
			return Promise.resolve ("12345");
		},
	}
};

export { academicService };
